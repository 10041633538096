.aftercare-container {
    width: 100%;
    margin: 0 auto;
    color: #5b3c14;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.7);
    background-image: url('../assets/background-2.jpg');
    background-size: cover; 
    background-position: center;
}

.aftercare-container form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.aftercare-container textarea {
    width: 90%;
    margin-bottom: 10px;
}

.aftercare-container button {
    width: 100px; 
}

.quote-section, .intro-section, .what-we-do-section, .reflection-section {
    background-color: rgba(255, 255, 255, 0.8); 
    width: 50%;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
}

.quote-section blockquote {
    font-style: italic;
    color: #333;
}

.quote-section blockquote span {
    display: block;
    margin-top: 10px;
    text-align: right;
    font-weight: bold;
}

.intro-section .image-container {
    text-align: center;
    margin-bottom: 20px;
}

.intro-section .image-container img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
}

.intro-section p, .intro-section ul {
    color: #333;
}

.intro-section ul {
    list-style-type: disc;
    margin-left: 20px;
}

.reflection-section h3 {
    color: #333;
}

.reflection-section form {
    display: flex;
    flex-direction: column;
}

.reflection-section textarea {
    resize: none;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.reflection-section button {
    padding: 10px;
    border-radius: 5px;
    border: none;
    background-color: #007bff;
    color: white;
    cursor: pointer;
}

.reflection-section button:hover {
    background-color: #0056b3;
}

.factors-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 20px;
}

.factor {
    background-color: #f0f0f0;
    padding: 10px;
    text-align: center;
    border: 1px solid #ddd;
    border-radius: 5px;
    flex: 1 1 calc(50% - 20px);
    box-sizing: border-box;
}
