.social-media-links {
    text-align: center;
    color: #5b3c14;
    padding: 20px 0;
    background-color: #f0f0f0;
    margin-top: 10px; 
    font-size: 14px;
}

.social-icons {
    display: flex;
    color: #5b3c14;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 10px; 
}

.social-icons a {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #333;
    text-decoration: none;
    font-size: 14px;
}

.social-icons img {
    width: 45px; 
    height: 40px;
    transition: transform 0.3s;
    margin-bottom: 5px;
}

.social-icons a:hover {
    color: #007bff;
}

.social-icons img:hover {
    transform: scale(1.2);
}
