.talent-bank-development-container {
    width: 100%;
    margin: 0 auto;
    color: #5b3c14;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.7);
    background-image: url('../assets/background-4.jpg');
    background-size: cover; 
    background-position: center;
}

.quote-section, .intro-section, .what-we-do-section {
    background-color: rgba(255, 255, 255, 0.8); 
    width: 50%;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
}

.quote-section blockquote {
    font-style: italic;
    color: #333;
}

.quote-section blockquote span {
    display: block;
    margin-top: 10px;
    text-align: right;
    font-weight: bold;
}

.intro-section p, .what-we-do-section p {
    color: #333;
}

.induction-steps {
    margin-top: 10px;
}

.induction-steps ol {
    list-style-type: decimal;
    margin-left: 20px;
    color: #333;
    font-weight: bold;
}

.induction-steps li {
    margin-bottom: 10px;
}
