.donation-details-page {
    display: flex;
    flex-direction: column;
    color: #5b3c14;
    align-items: center;
    padding: 40px 20px;
    background-color: #f0f4f8;
    font-family: Arial, sans-serif;
}

.container {
    background: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: 20px 0;
    width: 90%;
    max-width: 600px;
    text-align: center;
}

.qr-code-image {
    width: 200px;
    height: 200px;
    border: 2px solid #ddd;
    border-radius: 10px;
    margin-bottom: 20px;
}

.bank-details p {
    margin: 5px 0;
    font-size: 16px;
}

.done-button {
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;
}

.done-button:hover {
    background-color: #45a049;
}

.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    position: relative;
    width: 80%;
    max-width: 500px;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 24px;
    cursor: pointer;
}

.thank-you-message {
    color: #4CAF50; 
    margin-top: 20px;
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
