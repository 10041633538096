.gcp-container {
    width: 100%;
    margin: 0 auto;
    color: #5b3c14;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.7);
    background-image: url('../assets/background-1.JPG');
    background-size: cover; 
    background-position: center;
    font-size: 18px; 
}

.quote-section {
    background-color: rgba(255, 255, 255, 0.8); 
    width: 60%;
    padding: 10px 20px;
    border-radius: 10px;
    margin-bottom: 20px;
    font-weight: bold; 
    text-align: justify;
    
}

.quote-section h2 {
    font-size: 24px; 
    font-weight: bold; 
}

.quote-section blockquote {
    font-style: italic;
    color: #333;
    font-size: 20px; 
}

.quote-section blockquote span {
    display: block;
    margin-top: 10px;
    text-align: right;
    font-weight: bold;
    font-size: 18px; 
}

.intro-section, .what-we-do-section {
    background-color: rgba(255, 255, 255, 0.8); 
    width: 60%;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 10px;
    text-align: justify;
}

.intro-section h3, .what-we-do-section h3 {
    font-size: 22px; 
    font-weight: bold; 
}

.intro-section p, .what-we-do-section p {
    font-size: 18px; 
    font-weight: bold; 
}

.intro-section ul {
    list-style-type: disc;
    margin-left: 20px;
    color: #333;
    font-size: 18px; 
}

.what-we-do-section .traits-table {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    border-collapse: collapse;
}

.what-we-do-section .traits-table td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: center;
    color: #333;
    font-size: 18px; 
    font-weight: bold; 
}
